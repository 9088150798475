import './Hero.css';
import Logo from '../../../assets/img/Logo.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Hero = () => {
    const { t } = useTranslation(); // useTranslation hook to get the translation function

    return (
        <section id="hero" className="hero section">
          <div className="container">
            <div className="row gy-4 justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center hero-main">
                <h1 data-aos="fade-up">{t('hero_title')}</h1>
                <p data-aos="fade-up" data-aos-delay="100">{t('hero_description')}</p>
                <div className="d-flex flex-column flex-md-row" data-aos="fade-up" data-aos-delay="200">
                  <Link to="/#services" className="btn-get-started">
                    {t('hero_button')} <i className="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-out">
                <img 
                  src={Logo} 
                  className="img-fluid animated custom-image" 
                  alt="Logo" 
                  loading="lazy" 
                  style={{
                    width: '100%',
                    maxHeight: '300px',
                    objectFit: 'contain'
                  }} 
                />
              </div>
            </div>
          </div>
        </section>
    );
}

export default Hero;
