import './About.css';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook

import icon1 from '../../../assets/img/icon1.png';
import icon2 from '../../../assets/img/icon2.png';
import icon3 from '../../../assets/img/icon3.png';
import icon4 from '../../../assets/img/icon4.png';
import { Link } from 'react-router-dom';


const About = () => {
  const { t } = useTranslation(); // Initialize the translation function

  
    return (
        <section id="about" className="about section"> {/* Use className instead of class */}
        
          <div className="container section-title" data-aos="fade-up">
          <h2>{t('about_us')}</h2> {/* Dynamic translation for title */}
          </div>
          <div className="container">
        
            <div className="row gy-5">
        
              <div className="content col-xl-5 d-flex foundryByte-about flex-column" data-aos="fade-up" data-aos-delay="100">
                <h3>FoundryByte</h3>
                <p>{t('about_description')}</p> {/* Dynamic translation for the company description */}

                <Link to="#contact" className="about-btn align-self-center align-self-xl-start">
                <span>{t('contact_us')}</span> {/* Dynamic translation for contact button */}
                <i className="bi bi-chevron-right"></i>
                </Link>
              </div>
        
              <div className="col-xl-7" data-aos="fade-up" data-aos-delay="200">
                <div className="row gy-4 gx-4">
        
                  <div className="col-md-5 icon-box position-relative">
                  <div className="icon">
                    <img loading="lazy"  src={icon4} alt="Innovative Solutions" style={{ width: '80px', height: '80px' }} />
                  </div>                  
                  <h4><a href="javascript:void(0)" className="stretched-link">{t('innovative_solutions')}</a></h4>
                    <p>{t('innovative_description')}</p> {/* Dynamic translation for innovative solutions */}
                  </div>
        
                  <div className="col-md-5 icon-box position-relative">
                  <div className="icon">
                    <img loading="lazy"  src={icon2} alt="Innovative Solutions" style={{ width: '80px', height: '80px' }} />
                  </div>
                  <h4><a href="javascript:void(0)" className="stretched-link">{t('customer_centric_approach')}</a></h4>
                  <p>{t('customer_centric_description')}</p> {/* Dynamic translation for customer-centric approach */}
                  </div>
        
                  <div className="col-md-5 icon-box position-relative">
                  <div className="icon">
                    <img loading="lazy"  src={icon1} alt="Innovative Solutions" style={{ width: '80px', height: '80px' }} />
                  </div>
                  <h4><a href="javascript:void(0)" className="stretched-link">{t('agility_flexibility')}</a></h4>
                  <p>{t('agility_flexibility_description')}</p> {/* Dynamic translation for agility and flexibility */}
                  </div>
        
                  <div className="col-md-5 icon-box position-relative">
                  <div className="icon">
                    <img loading="lazy"  src={icon3} alt="Innovative Solutions" style={{ width: '80px', height: '80px' }} />
                  </div>                        
                  <h4><a href="javascript:void(0)" className="stretched-link">{t('expert_team')}</a></h4>
                  <p>{t('expert_team_description')}</p> {/* Dynamic translation for expert team */}
                  </div>
        
                </div>
              </div>
        
            </div>
        
          </div>
        
        </section>
    );
}

export default About;
