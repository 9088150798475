import './styles/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from './sections/header/Header';
import Footer from './sections/footer/Footer';
import Home from './pages/Home/Home';
import Project from './pages/project/Project';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import ScrollToTop from './sections/scrollToTop/ScrollToTop';


function App() {
  
   
  return (
    <Router>
      <Header />
      <ScrollToTop /> 
      <Routes>
        {/* Define your routes here */}
        <Route path="/" element={<Home />} />
        <Route path="/projet/:status" element={<Project />} /> {/* Updated path to include status */}
        {/* Add more routes as needed */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
