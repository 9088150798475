import React from 'react';
import './Tech.css';
import iconData from '../../../data/iconData'; // Adjust the path according to where you place iconData.js
import { useTranslation } from 'react-i18next'; // Import the translation hook

const Tech = () => {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <section id="features" className="features section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Technologies</h2>
        <p>{t('technologies_description')}</p> {/* Dynamic translation for description */}
      </div>

      <div className="container">
        <div className="row gy-4">
          {iconData.map((item, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-4"
              data-aos="fade-up"
              data-aos-delay={item.delay}
            >
              <div className="features-item">
              <img loading="lazy"  src={item.icon} alt={item.title} style={{ width: '50px', height: '50px' }} />
              <h3>
                  <a href="javascript:void(0)" className="stretched-link tech-title">{item.title}</a>
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Tech;
