import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import navigation hook
import './Service.css';
import serviceData from '../../../data/serviceData';  
import { useTranslation } from 'react-i18next'; // Import the translation hook

const Service = () => {
  const navigate = useNavigate(); // Initialize the hook
  const { t } = useTranslation(); // Initialize translation hook

  const handleClick = (status) => {
    // Navigate to the Project page and pass the status as a parameter
    navigate(`/projet/${status}`);
  };

  return (
    <section id="services" className="services section">
      <div className="container section-title" data-aos="fade-up">
        <h2>{t('services_title')}</h2> {/* Translate the title dynamically */}
      </div>

      <div className="container">
        <div className="row gy-4">
          {serviceData.map((service, index) => (
            <div key={index} className="col-xl-4 col-md-6 d-flex" data-aos="fade-up" data-aos-delay={service.delay}>
              <div className="service-item position-relative" onClick={() => handleClick(service.status)}>
              <img loading="lazy"  src={service.icon} alt={t(service.titleKey)} style={{ width: '60px', height: '60px' }} /> 
              <h4 className="stretched-link">{t(service.titleKey)}</h4> {/* Translate the title dynamically */}
                <p>{t(service.descriptionKey)}</p> {/* Translate the description dynamically */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
