import './Contact.css';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Contact = () => {
  const { t } = useTranslation(); // Initialize translation function

  return (
    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>{t('contact_title')}</h2>
        <p>{t('contact_description')}</p> 
      </div>

      <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-5">
            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
              <i className="bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h3>{t('address')}</h3> 
                <p>{t('address_details')}</p> 
              </div>
            </div>

            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
              <i className="bi bi-telephone flex-shrink-0"></i>
              <div>
                <h3>{t('call_us')}</h3> 
                <p>{t('phone_number')}</p>
              </div>
            </div>

            <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
              <i className="bi bi-envelope flex-shrink-0"></i>
              <div>
                <h3>{t('email_us')}</h3>
                <p>{t('email_address')}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="map-container" data-aos="fade-up" data-aos-delay="500">
              <iframe
                title="Google Map Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19224.022516892397!2d-6.862528906337754!3d34.000172728403605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda76c8f7837968b%3A0x6eb7a868063bb38b!2sAgdal-Ryad%2C%20Rabat!5e0!3m2!1sen!2sma!4v1730119528660!5m2!1sen!2sma"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;


