import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); // Persist language in localStorage
  };

  return (
<div className="language-switcher">
  <button className="btn btn-outline-primary rounded-pill mx-2" onClick={() => changeLanguage('en')}>EN</button>
  <button className="btn btn-outline-primary rounded-pill mx-2" onClick={() => changeLanguage('fr')}>FR</button>
</div>
  );
};

export default LanguageSwitcher;
