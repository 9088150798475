import './Footer.css';
import { useTranslation } from 'react-i18next';  // Import translation hook
import { useNavigate, Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const navigate = useNavigate();
  const location = useLocation();

  // Function to navigate based on the status
  const handleNavigation = (event, status) => {
    event.preventDefault(); 
    navigate(`/projet/${status}`);
  };


    // Define the handleScrollToSection function
    const handleScrollToSection = (event, sectionId) => {
      event.preventDefault();
  
      // If on the home page, scroll to the section directly
      if (location.pathname === '/') {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // If on a different page, navigate to the home page with the section hash
        navigate(`/#${sectionId}`);
      }
    };


  return (
    <footer id="footer" className="footer">
      <div className="container footer-top">
        <div className="row gy-4">

          <div className="col-lg-4 col-md-4 footer-about">
            <a href="index.html" className="d-flex align-items-center">
              <span className="sitename">FoundryByte</span>
            </a>
            <div className="footer-contact pt-3">
              <p>Rabat, Agdal</p>
              <p className="mt-3">
                <strong>Phone:</strong> 
                <span>+212 694647806</span>
                <br />
                <span className='ms-5'>+212 667523171</span>
              </p>
              <p><strong>Email:</strong> <span>foundrybyte@gmail.com</span></p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 footer-links">
            <h4>{t('Footer_links')}</h4>
            <ul>
              <li><i className="bi bi-chevron-right"></i>
                <Link to="/#hero" onClick={(e) => handleScrollToSection(e, 'hero')}>Home</Link>
              </li>
              <li><i className="bi bi-chevron-right"></i>
                <Link to="/#services" onClick={(e) => handleScrollToSection(e, 'services')}>Services</Link>
              </li>
              <li><i className="bi bi-chevron-right"></i>
                <Link to="/#about" onClick={(e) => handleScrollToSection(e, 'about')}>About</Link>
              </li>
              <li><i className="bi bi-chevron-right"></i>
                <Link to="/#features" onClick={(e) => handleScrollToSection(e, 'features')}>Technologies</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-4 footer-links">
            <h4>{t('Footer_services')}</h4>
            <ul>
              <li><i className="bi bi-chevron-right"></i><a href={`/projet/1`} onClick={(e) => handleNavigation(e, 1)}>Solutions</a></li>
              <li><i className="bi bi-chevron-right"></i><a href={`/projet/3`} onClick={(e) => handleNavigation(e, 3)}>WebSite</a></li>
              <li><i className="bi bi-chevron-right"></i><a href={`/projet/2`} onClick={(e) => handleNavigation(e, 2)}>Mobile App</a></li>
              <li><i className="bi bi-chevron-right"></i><a href={`/projet/5`} onClick={(e) => handleNavigation(e, 5)}>Automatisation</a></li>
            </ul>
          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
