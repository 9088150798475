import React, { useEffect, useState } from "react";
import '../../styles/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import AOS from "aos";
import GLightbox from "glightbox";
import Isotope from "isotope-layout";
import Swiper from "swiper";
import 'swiper/swiper-bundle.min.css';
import "aos/dist/aos.css";
import "glightbox/dist/css/glightbox.css";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import LanguageSwitcher from './LanguageSwitcher';  // Import LanguageSwitcher
import { useTranslation } from 'react-i18next';  // Import translation hook
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';



function Header() {
    const location = useLocation();

    const [isMobileNavActive, setIsMobileNavActive] = useState(false);
    const { t } = useTranslation(); // Initialize the translation hook


    useEffect(() => {
      // Toggle scrolled class on body based on scroll position
      function toggleScrolled() {
        const selectBody = document.querySelector("body");
        const selectHeader = document.querySelector("#header");
        if (!selectHeader) return;
        window.scrollY > 100
          ? selectBody.classList.add("scrolled")
          : selectBody.classList.remove("scrolled");
      }

      window.addEventListener("scroll", toggleScrolled);
      window.addEventListener("load", toggleScrolled);

      // Init AOS
      AOS.init({
        duration: 600,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });

      // Initiate GLightbox
      GLightbox({
        selector: ".glightbox",
      });

      // Init isotope layout and filters
      const isotopeLayouts = document.querySelectorAll(".isotope-layout");
      isotopeLayouts.forEach(function (isotopeItem) {
        let layout = isotopeItem.getAttribute("data-layout") || "masonry";
        let filter = isotopeItem.getAttribute("data-default-filter") || "*";
        let sort = isotopeItem.getAttribute("data-sort") || "original-order";

        let initIsotope;
        // imagesLoaded(isotopeItem.querySelector(".isotope-container"), function () {
        //   initIsotope = new Isotope(isotopeItem.querySelector(".isotope-container"), {
        //     itemSelector: ".isotope-item",
        //     layoutMode: layout,
        //     filter: filter,
        //     sortBy: sort,
        //   });
        // });

        isotopeItem.querySelectorAll(".isotope-filters li").forEach(function (filters) {
          filters.addEventListener("click", function () {
            isotopeItem
              .querySelector(".isotope-filters .filter-active")
              .classList.remove("filter-active");
            this.classList.add("filter-active");
            initIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
            AOS.refresh(); // Re-initialize AOS after Isotope filtering
          });
        });
      });

      // Init Swiper
      function initSwiper() {
        document.querySelectorAll(".init-swiper").forEach(function (swiperElement) {
          let config = JSON.parse(
            swiperElement.querySelector(".swiper-config").innerHTML.trim()
          );
          new Swiper(swiperElement, config);
        });
      }

      window.addEventListener("load", initSwiper);
      
      ReactGA.send({ hitType: 'pageview', page: location.pathname });

      return () => {
        // Clean up event listeners on unmount
        window.removeEventListener("scroll", toggleScrolled);
        window.removeEventListener("load", toggleScrolled);
      };

    }, [location]);

    // Function to toggle mobile nav
    function mobileNavToggle() {
      setIsMobileNavActive((prev) => !prev);
    }

    function handleLinkClick() {
      setIsMobileNavActive(false);
    }

    return (
      <header id="header" className={`header d-flex align-items-center sticky-top ${isMobileNavActive ? 'mobile-nav-active' : ''}`}>
        <div className="container-fluid position-relative d-flex align-items-center justify-content-between">
          <Link to="/#hero" className="logo d-flex align-items-center me-auto me-xl-0">
            <h1 className="sitename" style={{ color: '#007FFF' }}>FoundryByte</h1><span>.</span>
          </Link>
          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <Link to="/#hero" style={{ fontWeight: 'bold' }} onClick={handleLinkClick}>
                  {t('home')}
                </Link>
              </li>
              <li>
                <Link to="/#about" style={{ fontWeight: 'bold' }} onClick={handleLinkClick}>
                  {t('about_header')}
                </Link>
              </li>
              <li>
                <Link to="/#services" style={{ fontWeight: 'bold' }} onClick={handleLinkClick}>
                  {t('services_header')}
                </Link>
              </li>
              <li>
                <Link to="/#methodes" style={{ fontWeight: 'bold' }} onClick={handleLinkClick}>
                  {t('methode_header')}
                </Link>
              </li>
              <li>
                <Link to="/#call-to-action" style={{ fontWeight: 'bold' }} onClick={handleLinkClick}>
                  {t('cta_header')}
                </Link>
              </li>
              <li>
                <Link to="/#features" style={{ fontWeight: 'bold' }} onClick={handleLinkClick}>
                  {t('technologies_header')}
                </Link>
              </li>
              <li>
                <Link to="/#contact" style={{ fontWeight: 'bold' }} onClick={handleLinkClick}>
                  {t('contact_header')}
                </Link>
              </li>
            </ul>
            <i className={`mobile-nav-toggle d-xl-none bi ${isMobileNavActive ? 'bi-x' : 'bi-list'}`} onClick={mobileNavToggle}></i>
          </nav>
          <LanguageSwitcher /> {/* Language switcher added here */}
        </div>
      </header>
    );
}

export default Header;
